<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <!-- Header Section -->
        <div class="d-flex align-items-center justify-content-between px-3 pt-2 mt-0">
          <div class="d-flex align-items-center gap-3">
            <h5 style="font-weight: bold; padding-top: 18px; position: sticky">
              Goods Received Note
            </h5>
          </div>
          <div class="search-container">
            <span>
              <img
                src="../../assets/icons/cancelIcon.png"
                class="search-img"
                alt="Cancel"
                onclick="window.history.back();"
              />
            </span>
          </div>
        </div>
        <form>
          <div class="form-container">
            <!-- Type Selection Dropdown -->
            <div class="row">
              <div class="input-group small-select">
                <label for="type">Type</label>
                <select id="type" v-model="selectedType" @change="fetchCompanyAddressPo">
                  <option value="Company">Company</option>
                  <option value="Project">Project</option>
                </select>
              </div>
            </div>

            <!-- Row 1 -->
            <div class="row">
              <div class="input-group">
                <label for="project">Search and Select Project</label>
                <input
                  id="project"
                  type="text"
                  v-model="searchString"
                  @input="searchProjects"
                  placeholder="Search projects"
                  :disabled="selectedType === 'Company'"
                />
                <ul
                  v-if="selectedType === 'Project' && ProjectList.length > 0"
                  class="project-list"
                >
                  <li
                    v-for="project in ProjectList"
                    :key="project.id"
                    @click="selectProject(project)"
                  >
                    {{ project.projectName }}
                  </li>
                </ul>
              </div>

              <div class="input-group">
                <label for="poNumber">PO Number</label>
                <select
                  id="poNumber"
                  v-model="selectedPONumber"
                  @change="displayFields()"
                >
                  <option v-for="po in Podata" :key="po.id" :value="po.poNumber">
                    {{ po.poNumber }}
                  </option>
                </select>
              </div>

              <div class="input-group">
                <label for="input2">Order Date</label>
                <input type="date" id="input2" v-model="formFields.date" />
              </div>
            </div>

            <!-- Row 2 -->
            <div class="row">
              <div class="input-group">
                <label for="input3">Vendor Name</label>
                <input type="text" id="input3" v-model="formFields.vendorName" />
              </div>
              <div class="input-group">
                <label for="input4">Vendor contact No.</label>
                <input type="text" id="input4" v-model="formFields.vendorContactNo" />
              </div>
              <div class="input-group">
                <label for="input5">Delivery Document Type</label>
                <select id="input5" v-model="formFields.deliveryDocType">
                  <option value="Delivery Challan">Delivery Challan</option>
                  <option value="Tax Invoice">Tax Invoice</option>
                </select>
              </div>
            </div>

            <!-- Row 3 -->
            <div class="row">
              <div class="input-group">
                <label for="input6">Document No.</label>
                <input type="text" id="input6" v-model="formFields.documentNo" />
              </div>
              <div class="input-group">
                <label for="input7">Received Date</label>
                <input type="date" id="input7" v-model="formFields.receivedDate" />
              </div>
              <div class="input-group">
                <label for="input8">Receiver Name</label>
                <input type="text" id="input8" v-model="formFields.receiverName" />
              </div>
            </div>

            <!-- Row 4 -->
            <div class="row">
              <div class="input-group" @change="handleFileUpload">
                <label for="input8">Upload File</label>
                <input class="upload" type="file" id="input8" ref="Attachment" />
              </div>

              <!-- Conditionally Rendered Invoice Fields -->
              <div
                class="input-group"
                v-if="formFields.deliveryDocType === 'Tax Invoice'"
              >
                <label for="input7">Invoice Date</label>
                <input type="date" id="input7" v-model="formFields.invoiceDate" />
              </div>

              <div
                class="input-group"
                v-if="formFields.deliveryDocType === 'Tax Invoice'"
              >
                <label for="input8">Invoice Amount</label>
                <input type="text" id="input8" v-model="formFields.invoiceAmount" />
              </div>
            </div>
          </div>

          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <!-- <th>Product Id</th> -->
                  <th>Product Name</th>
                  <th>Order Qty</th>
                  <th>Received Qty</th>
                  <th>Return Qty</th>
                  <th>Damaged Qty</th>
                  <th>Missing Qty</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in lineItems" :key="index">
                  <!-- Product Name -->
                  <td class="product-name">
                    <input
                      type="text"
                      :value="item['productLists.productName']"
                      readonly
                    />
                  </td>
                  <!-- Quantity column with checkbox and input -->
                  <td>
                    <div
                      style="display: flex; align-items: center; gap: 10px; width: 100px"
                    >
                      <!-- Checkbox -->
                      <input
                        type="checkbox"
                        v-model="item.isChecked"
                        @change="handleCheckboxChange(item)"
                      />
                      <!-- Quantity Input -->
                      <input
                        type="number"
                        v-model="item.quantity"
                        style="width: 60px"
                        readonly
                      />
                    </div>
                  </td>
                  <!-- Received Quantity -->
                  <td class="small-size">
                    <input
                      type="number"
                      v-model="item.receivedQty"
                      :readonly="item.isChecked || item.isSatisfied"
                    />
                  </td>
                  <!-- Return Quantity -->
                  <td class="small-size">
                    <input
                      type="number"
                      v-model="item.returnQty"
                      :readonly="item.isChecked || item.isSatisfied"
                    />
                  </td>
                  <!-- Damaged Quantity -->
                  <td class="small-size">
                    <input
                      type="number"
                      v-model="item.damagedQty"
                      :readonly="item.isChecked || item.isSatisfied"
                    />
                  </td>
                  <!-- Missing Quantity -->
                  <td class="small-size">
                    <input
                      type="number"
                      v-model="item.missingQty"
                      :readonly="item.isChecked || item.isSatisfied"
                    />
                  </td>
                  <!-- Remarks -->
                  <td>
                    <input
                      type="text"
                      v-model="item.remark"
                      :readonly="item.isChecked || item.isSatisfied"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="buttons d-flex">
            <!-- Save Button -->
            <!-- Submit Button -->
            <button
              class="btn m btn-primary submit-button"
              type="button"
              @click="submitGRN"
            >
              Submit
            </button>
            <!-- Cancel Button -->
            <button
              class="btn m btn-secondary submit-button"
              type="button"
              onclick="window.history.back();"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  components: {
    Header,
    Nav,
  },

  data() {
    return {
      selectedType: "Project", // Default selection
      searchString: "",
      ProjectList: [],
      selectedProjectId: null,
      Podata: [],
      lineItems: [],
      selectedPoId: "",
      formFields: {
        date: "",
        vendorName: "",
        invoiceAmount: "",
        recieverContactNo: "",
        deliveryDocType: "",
        documentNo: "",
        receivedDate: "",
        receiverName: "",
        storeName: "",
        fileName: "",
        products: [],
        awsFileId: null,
        comments: "",
      },
    };
  },

  watch: {
    selectedType(newValue) {
      console.log("Selected Type:", newValue);
      if (this.selectedType == "Project") {
        this.Podata = [];
      }
    },
  },

  computed: {
    filteredPONumbers() {
      const selectedProject = this.formFields.projectName;
      return this.Podata.filter((po) => po["projects.projectName"] === selectedProject);
    },
  },

  methods: {
    fetchCompanyAddressPo() {
      if (this.selectedType === "Company") {
        axios
          .get(`${this.$apiEndPoint}/purchaseOrder/companyAddressPo`)
          .then((response) => {
            this.Podata = response.data;
          })
          .catch((error) => {
            console.error("Error fetching company address:", error);
          });
      }
    },

    handleCheckboxChange(item) {
      // Toggle all other inputs to read-only when checkbox is checked
      if (item.isChecked) {
        item.receivedQty = item.quantity;
        item.returnQty = 0;
        item.damagedQty = 0;
        item.missingQty = 0;
        item.remark = "All Items Recieved";
      }
    },

    handleFileUpload() {
      const file = this.$refs.Attachment.files[0];
      console.log(file);

      if (file) {
        // Initialize variables for fileCategory and moduleName
        let fileCategory = "";
        let moduleName = "";

        // Check the dropdown value and assign appropriate values
        if (this.formFields.deliveryDocType === "Delivery Challan") {
          fileCategory = "deliveryChallen";
          moduleName = "procurement";
        } else if (this.formFields.deliveryDocType === "Tax Invoice") {
          fileCategory = "purchaseInvoice";
          moduleName = "procurement";
        } else {
          console.error("Invalid Delivery Document Type selected");
          return; // Exit the function if the selection is invalid
        }

        // Call the API with the determined fileCategory and moduleName
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: fileCategory,
            moduleName: moduleName,
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.formFields.awsFileId = response.data.id; // Update awsFileId
            this.formFields.fileName = file.name; // Update fileName
            console.log(this.formFields);
            console.log(response.data);

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4 && xhr.status === 200) {
                console.log("File uploaded successfully");
              } else if (xhr.readyState === 4) {
                console.error("Error uploading file to S3:", xhr.responseText);
              }
            };

            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    async fetchPurchaseOrderDetails(poId) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/purchaseOrder/${poId}`);
        // Initialize additional fields for receivedQty and returnQty if needed
        this.lineItems = response.data.Lineitems.map((item) => ({
          ...item,
        }));
        console.log("Fetched purchase order details:", this.lineItems);
      } catch (error) {
        console.error("Error fetching purchase order details:", error);
      }
    },

    displayFields() {
      const selectedPO = this.Podata.find((po) => po.poNumber === this.selectedPONumber);
      if (selectedPO) {
        // Fill form fields
        this.selectedPoId = selectedPO.id;
        this.formFields.date = selectedPO.date;
        this.formFields.vendorName = selectedPO["vendors.companyName"];
        this.formFields.vendorContactNo = selectedPO["vendors.primaryContactNo"];
        this.formFields.products = selectedPO.products;
        this.formFields.comments = selectedPO.comments;

        this.fetchPurchaseOrderDetails(selectedPO.id);
      }
    },

    async searchProjects() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/projects/In Progress/All/All`,
          { searchString: this.searchString }
        );

        if (response.data && response.data.rows) {
          this.ProjectList = response.data.rows;
        } else {
          this.ProjectList = [];
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    },

    selectProject(project) {
      this.selectedProjectId = project.id;
      this.searchString = project.projectName; // Optionally reset search input to selected project name
      this.ProjectList = []; // Clear the list after selection (optional)
      this.onProjectChange(); // Trigger any function after project selection
    },

    onProjectChange() {
      console.log("Project changed, selectedProjectId:", this.selectedProjectId);
      if (this.selectedProjectId) {
        this.fetchPONumbers(this.selectedProjectId);
      }
    },

    async fetchPONumbers(projectId) {
      try {
        console.log("Fetching PO numbers for project ID:", projectId);
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/getPoNoForInventory/${projectId}`
        );

        if (response.data && Array.isArray(response.data)) {
          this.Podata = response.data;
          console.log("Fetched PO numbers:", this.Podata);
        } else {
          console.error("Unexpected API response format:", response.data);
          this.Podata = [];
        }
      } catch (error) {
        console.error("Error fetching PO numbers:", error.message);
        this.$handleError(error);
      }
    },

    resetFormFields() {
      (this.formFields = {
        date: "",
        vendorName: "",
        invoiceAmount: "",
        recieverContactNo: "",
        deliveryDocType: "",
        documentNo: "",
        receivedDate: "",
        receiverName: "",
        storeName: "",
        fileName: "",
        products: [],
        awsFileId: null,
        comments: "",
      }),
        (this.lineItems = []);
      this.selectProject = "";
    },

    async submitGRN() {
      try {
        const payload = {
          poId: this.selectedPoId,
          projectId: this.selectedProjectId,
          date: this.formFields.date,
          vendorName: this.formFields.vendorName,
          amount: this.formFields.invoiceAmount,
          vendorContactNo: this.formFields.vendorContactNo,
          documentType: this.formFields.deliveryDocType,
          documentNo: this.formFields.documentNo,
          receivedDate: this.formFields.receivedDate,
          receiverName: this.formFields.receiverName,
          awsFileId: this.formFields.awsFileId,
          lineItems: this.lineItems.map((item) => ({
            productId: item.productId,
            orderQuantity: item.quantity,
            returnQuantity: item.returnQty,
            receivedQuantity: item.receivedQty,
            damagedQuantity: item.damagedQty,
            missingQuantity: item.missingQty,
            remark: item.remark,
          })),
        };
        console.log("data:", payload);

        const response = await axios.post(`${this.$apiEndPoint}/OnSiteGrn/`, payload);
        console.log("Created Grn:", response);
        this.resetFormFields();
        this.$toast("GRN Created Successfully", "success");
      } catch (error) {
        console.error("Error submitting Goods Received Note:", error);
        console.log(error);
        alert("An error occurred while submitting the Goods Received Note.");
      }
    },
  },
};
</script>

<style scoped>
/* Smaller size styling for the input group */
.input-group.small-select {
  font-size: 12px; /* Reduce font size */
  width: 50px; /* Adjust container width */
}

/* Reduce spacing and size of label */
.input-group.small-select label {
  font-size: 12px;
  margin-bottom: 4px; /* Reduce spacing below the label */
  display: block; /* Ensure proper alignment */
}

/* Styling for the select dropdown */
.input-group.small-select select {
  font-size: 12px; /* Smaller font size */
  padding: 4px 8px; /* Adjust padding for smaller size */
  height: auto; /* Allow dynamic height */
  width: 30%; /* Ensure it matches the container width */
  border-radius: 4px; /* Optional: round the edges slightly */
}

.small-size {
  width: 200px;
  background-color: none;
}

.product-name {
  width: 300px;
}

.submit-button {
  padding: 7px;
  font-size: small;
}

.project-list {
  max-height: 100px;
  width: 85%;
  overflow-y: auto;
  font-size: small;
  border: 1px solid #ddd;
  margin-top: 5px;
  padding-left: 0;
  list-style-type: none;
  top: 100%;
  z-index: 999;
  background-color: #ffffff;
  position: absolute;
}

.project-list li {
  padding: 10px;
  cursor: pointer;
}

.project-list li:hover {
  background-color: #4a4848;
  color: #ffffff;
}

.buttons {
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
  gap: 5%;
  font-size: small;
}

.table-container {
  margin: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  padding: 12px;
  border: 1px solid #ddd;
}

thead th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tbody td input {
  width: 100%;
  border: none;
  padding: 8px;
  outline: none;
  background-color: #f9f9f9;
  border-radius: 4px;
}

tbody td input:focus {
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-container {
  background: #dddcdc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

/* Row Styling */
.row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

.input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 32%;
}

input,
select {
  width: 100%;
  box-sizing: border-box;
}

.upload {
  flex: 1;
  padding: 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 423px;
}

.input-group select {
  padding: 8px 10px;
  border: none;
  border-radius: 8px !important;
  /* Ensure rounded corners */
  background-color: #ffffff;
  font-size: 14px;
  outline: none;
  margin: 0;
  width: 90%;
  /* Ensure consistent width with input */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.input-group select:hover {
  background-color: rgb(255, 255, 255);
  /* Slightly darker background on hover */
}

.input-group select:focus {
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Add focus glow */
}

/* Label Styling */
label {
  font-weight: 500;
  font-size: 14px;
}

/* Input Styling */
input {
  padding: 8px 10px;
  border: none;
  border-radius: 8px !important;
  /* Add subtle background color */
  background-color: #ffffff;
  font-size: 14px;
  outline: none;
  /* Remove focus outline */
  margin: 0;
  /* Avoid unwanted spacing */
  width: 90%;
  /* Ensure consistent width */
}

input:focus {
  background-color: #ffffff;
  /* Change background color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Add focus glow */
}

/* Upload Button Styling */
.upload-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  /* Ensure border-radius matches inputs */
  cursor: pointer;
  align-self: flex-start;
}

.upload-btn:hover {
  background-color: #0056b3;
}

.search-img {
  width: 20px;
  height: 20px;
}
</style>
