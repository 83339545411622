<template>
  <!-- Modal for creating a new project -->
  <div
    class="modal fade"
    id="createProjectModal"
    tabindex="-1"
    aria-labelledby="createProjectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title SubheadingUnderLine" id="createProjectModalLabel">
            Assigne New Project
          </h5>
          <img
            src="../../assets/icons/cancel.png"
            alt=""
            data-bs-dismiss="modal"
            aria-label="Close"
            style="width: 20px; height: 20px; cursor: pointer"
          />
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label for="projectName" class="col-sm-4 col-form-label">Project Name:</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="projectName"
                v-model="newProject.projectName"
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="customerId" class="col-sm-4 col-form-label">Customer Name:</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="customerId"
                v-model="newProject.customerName"
                readOnly
                
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="customerId" class="col-sm-4 col-form-label">Customer ID:</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="customerId"
                v-model="newProject.customerNumber"
                readOnly
              />
            </div>
          </div>
          <div class="form-group row" v-if="this.projectId">
            <label for="familyStatus" class="col-sm-4 col-form-label"
              >Family Status:</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="familyStatus"
                v-model="newProject.familyStatus"
                required
              />
            </div>
          </div>

          <div class="form-group row" v-if="this.projectId">
            <label for="buildingConfiguration" class="col-sm-4 col-form-label"
              >Building Configuration:</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="buildingConfiguration"
                v-model="newProject.buildingConfiguration"
              />
            </div>
          </div>
          <div class="form-group row" v-if="this.projectId || this.ProcurementprojectId">
            <label for="assignTo" class="col-sm-4 col-form-label">Assigned To:</label>
            <div class="col-sm-8">
              <select
                v-model="newProject.assignTo"
                name="assignTo"
                id="assignTo"
                class="form-control form-select"
              >
                <option disabled value="">Select an employee</option>
                <option
                  v-for="employee in employees"
                  :key="employee.employeeId"
                  :value="employee.employeeId"
                >
                  {{ employee["employees.firstName"] }}
                  {{ employee["employees.lastName"] }}
                </option>
              </select>
            </div>
          </div>

          <!-- <div class="form-group row" v-if="this.projectId || this.ProcurementprojectId">
            <label for="assignTo" class="col-sm-4 col-form-label">Assigned To:</label>
            <div class="col-sm-8">
              <input
                type="search"
                v-model="searchEmployee"
                @input="searchEmployees"
                name="assignTo"
                id="assignTo"
                class="form-control"
                placeholder="Search here"
                v-capitalize-first-letter
              />
              <div
                v-if="showEmployeeDropdown && searchEmployee.length > 0"
                class="employee-dropdown"
              >
                <ul>
                  <li
                    v-for="employee in filteredemployees()"
                    :key="employee.id"
                    @click="selectEmployee(employee)"
                    style="cursor: pointer"
                    class="ms-1"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </li>
                </ul>
              </div>
            </div>
          </div> -->

          <div class="form-group row" v-if="this.OnSiteprojectId">
            <div class="col-sm-4">
              <label for="inputAssignee" class="form-label">Assign To:</label>
            </div>
            <div class="col-sm-8">
              <div
                v-if="selectedAssignees.length > 0"
                style="
                  font-size: 14px;
                  border: 2px solid #000;
                  margin: 5px;
                  padding: 5px;
                  border-radius: 5px;
                "
              >
                <div
                  v-for="(employeeId, index) in selectedAssignees"
                  :key="index"
                  class="selected-employee"
                >
                  {{ getEmployeeFullName(employeeId) }}
                  <span @click="removeEmployee(index)" class="remove-employee"
                    >&#10005;</span
                  >
                </div>
              </div>
              <div>
                <select
                  v-model="selectedEmployee"
                  class="form-control inputbg"
                  id="inputAssignee"
                  @change="addEmployee"
                >
                  <option disabled value="">Select an Employee</option>
                  <option
                    v-for="employee in filteredEmployees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                    class="EmployeeList"
                  >
                    {{ getEmployeeFullName(employee.employeeId) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-primary"
            @click="createProject"
            data-bs-dismiss="modal"
            :disabled="loading"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CreateDesignProjectModal",
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    OnSiteprojectId: {
      type: Number,
      required: true,
    },
    ProcurementprojectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading:false,
      searchText: "",
      selectedEmployee: "",
      selectedAssignees: [],
      employees: [], // Store fetched employees
      searchEmployee: "", // Input for searching employees
      showEmployeeDropdown: false, // Flag to show/hide employee dropdown
      NewProjects: [],

      selectedProjectId: this.projectId,
      newProject: {
        projectName: "",
        customerNumber: "",
        customerName: "",
        customerId: "",
        familyStatus: "",
        buildingConfiguration: "",
        assignTo: "",
      },
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter((employee) => {
        const fullName = `${employee["employees.firstName"]} ${employee["employees.lastName"]}`;
        return fullName.toLowerCase().includes(this.searchEmployee.toLowerCase());
      });
    },
  },
  mounted() {
    this.setSelectedProject();
  },
  watch: {
    projectId(newValue) {
      if (newValue !== null) {
        this.getNewProjects();
        this.selectedProjectId = newValue;
        this.fetchEmployees();
        this.setSelectedProject();
      } else {
        this.getNewProjects();
      }
    },
    OnSiteprojectId(newValue) {
      if (newValue !== null) {
        this.getNewOnSiteProjects();
        this.selectedProjectId = newValue;
        this.setSelectedProject();
        this.fetchEmployees();
      } else {
        this.getNewOnSiteProjects();
      }
    },
    ProcurementprojectId(newValue) {
      if (newValue !== null) {
        this.getNewProcurementProjects();
        this.selectedProjectId = newValue;
        this.setSelectedProject();
        this.fetchEmployees();
      } else {
        this.getNewOnSiteProjects();
      }
    },
  },

  methods: {
    setSelectedProject() {
      if (this.selectedProjectId) {
        const selectedProject = this.NewProjects.find(
          (project) => project.id === this.selectedProjectId
        );

        if (selectedProject) {
          // Common attributes
          this.newProject.customerId = selectedProject.customerId;
          this.newProject.customerNumber = selectedProject["customers.customerNumber"];
          this.newProject.customerName = selectedProject["customers.name"];

          if (this.projectId && !this.OnSiteprojectId) {
            this.newProject.projectName = selectedProject.projectName;
            this.newProject.familyStatus = selectedProject.familyStatus;
            this.newProject.buildingConfiguration = selectedProject.buildingConfiguration;
          } else if (!this.projectId && this.OnSiteprojectId) {
            this.newProject.projectName = selectedProject["projects.projectName"];
          } else if (this.ProcurementprojectId && !this.OnSiteprojectId) {
            this.newProject.projectName = selectedProject["projects.projectName"];
          }
        }
      }
    },

    fetchEmployees() {
      let endpoint = null;

      // Determine the correct API endpoint based on the project type
      if (this.projectId && !this.OnSiteprojectId) {
        endpoint = `${this.$apiEndPoint}/employee/myTeam/Designing`;
      } else if (!this.projectId && this.OnSiteprojectId) {
        endpoint = `${this.$apiEndPoint}/employee/myTeam/OnSite`;
      } else if (!this.projectId && this.ProcurementprojectId) {
        endpoint = `${this.$apiEndPoint}/employee/myTeam/Procurement`;
      }
      // Fetch employees if an endpoint is determined
      if (endpoint) {
        axios
          .get(endpoint)
          .then((response) => {
            // Filter out employees with null values
            this.employees = response.data.filter((item) => item !== null);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    // Method to search employees based on input
    searchEmployees() {
      this.showEmployeeDropdown = true;
    },
    addEmployee() {
      if (this.selectedEmployee) {
        const employeeIndex = this.filteredEmployees.findIndex(
          (emp) => emp.employeeId === this.selectedEmployee
        );
        if (employeeIndex !== -1) {
          this.filteredEmployees.splice(employeeIndex, 1); // Remove the selected employee from filteredEmployees
        }
        this.selectedAssignees.push(this.selectedEmployee);
        this.selectedEmployee = ""; // Clear the selectedEmployee after adding
      }
    },

    removeEmployee(index) {
      const removedEmployeeId = this.selectedAssignees.splice(index, 1)[0]; // Remove the employee ID from selectedAssignees
      const employee = this.employees.find((emp) => emp.employeeId === removedEmployeeId);
      if (employee) {
        this.filteredEmployees.push(employee); // Push the removed employee back to filteredEmployees
        this.filteredEmployees.sort((a, b) => a.employeeId - b.employeeId); // Sort the list to maintain consistency
      }
    },

    getEmployeeFullName(employeeId) {
      const employee = this.employees.find((emp) => emp.employeeId === employeeId);
      return employee
        ? `${employee["employees.firstName"]} ${employee["employees.lastName"]}`
        : "";
    },
    // Method to filter employees based on search input
    filteredemployees() {
      // Check if this.employees is a valid array
      if (!Array.isArray(this.employees)) {
     
        return [];
      }

      // If searchEmployee is a string, filter based on employee names
      if (this.searchEmployee && typeof this.searchEmployee === "string") {
        return this.employees.filter(
          (employee) =>
            employee?.["employees.firstName"]?.includes(this.searchEmployee) ||
            employee?.["employees.lastName"]?.includes(this.searchEmployee)
        );
      }

      // Return the full list if no search term is provided
      return this.employees;
    },

    // Method to select an employee
    selectEmployee(employee) {
      this.searchEmployee = `${employee["employees.firstName"]} ${employee["employees.lastName"]}`;
      this.newProject.assignTo = employee.employeeId;
      this.showEmployeeDropdown = false;
    },
    createProject() {
      this.loading=true;
      if (this.projectId && !this.OnSiteprojectId) {
        try {
      axios.put(
            `${this.$apiEndPoint}/designProjects/acceptProject/${this.selectedProjectId}`,
            this.newProject
          );
         
          this.AddCollaborators();
    
        } catch (error) {
          this.$handleError(error);
        }
      } else if (!this.projectId && this.OnSiteprojectId) {
        try {
     axios.put(
            `${this.$apiEndPoint}/onSiteProject/${this.selectedProjectId}`,
            this.newProject
          );
   
          this.createOnSiteSupervisor();
     
        } catch (error) {
          this.$handleError(error);
        }
      } else if (!this.projectId && this.ProcurementprojectId) {
        const requestData = {
          assignTo: this.newProject.assignTo,
        };
        try {
       axios.put(
            `${this.$apiEndPoint}/procurementProject/${this.selectedProjectId}`,
            requestData
          );
       
          this.AddCollaborators();
      
        } catch (error) {
          this.$handleError(error);
        }
      }
      this.newProject = {
        projectName: "",
        customerId: "",
        familyStatus: "",
        buildingConfiguration: "",
        assignTo: "",
      };
    },
    async createOnSiteSupervisor() {
      if (this.OnSiteprojectId) {
        this.selectedAssignees.push(this.$LocalUser.user.employeeId);
        try {
          const requestData = {
            employeeId: this.selectedAssignees, // Array of selected salesPersonIds
            onSiteProjectId: this.selectedProjectId,
          };

         axios.post(
            `${this.$apiEndPoint}/onSiteSupervisors/`,
            requestData
          );
          this.$emit("fetchData");
          location.reload();

          this.loading=false;
   
        } catch (error) {
          this.$handleError(error);
        }
      }
    },

    async AddCollaborators() {
      if (this.projectId) {
        try {
          const requestData = {
            employeeId: [this.$LocalUser.user.employeeId, this.newProject.assignTo],
            designProjectId: this.projectId,
          }; 

    axios.post(
            `${this.$apiEndPoint}/designProjectTeam/`,
            requestData
          );
      
          this.$emit("fetchData");
          location.reload();
          this.loading=false;
        } catch (error) {
          this.loading=false;
          this.$handleError(error);
        }
      } else {
        try {
          const requestData = {
            employeeId: [this.$LocalUser.user.employeeId, this.newProject.assignTo],
            procurementProjectId: this.ProcurementprojectId,
          };

        axios.post(
            `${this.$apiEndPoint}/procurementCollabs/`,
            requestData
          );
       
          this.$emit("fetchData");
          location.reload();

      
        } catch (error) {
          this.loading=false;
          this.$handleError(error);
        }
      }
    },

    async getNewOnSiteProjects() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteProject/projectStatus/New`
        );
        this.NewProjects = response.data;
        this.setSelectedProject();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async getNewProcurementProjects() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/procurementProject/New`);
        this.NewProjects = response.data;
        this.setSelectedProject();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getNewProjects() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/projectStatus/New/All`
        );
        this.NewProjects = response.data;
        this.setSelectedProject();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
.form-group {
  margin-top: 20px;
}
.form-group label {
  font-size: 14px;
  white-space: nowrap;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 36%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 40%); /* Adjust width to match the input */
  color: #000000;
  top: 98%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}

/* Add your scoped styles here */
.selected-employee {
  margin-bottom: 5px;
}

.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}
input,
select {
  border: 1px solid #000000;
  outline: none !important;
}
select {
  height: fit-content;
}
</style>
